<template>
  <div>
    <!-- offcanvas table settings -->
    <div
      id="offcanvasTableSettings"
      class="offcanvas offcanvas-end offcanvas-end--filters d-flex"
      tabindex="-1"
      aria-labelledby="offcanvasTableSettingsLabel"
      :class="{ show: visible }"
      :style="{ visibility: visible ? 'visible' : 'hidden' }"
    >
      <div class="offcanvas-header">
        <h3
          id="offcanvasTableSettingsLabel"
          class="offcanvas-title"
        >
          <span class="btn btn-icon btn-icon rounded-circle btn-flat-secondary bg-light-secondary me-2"><i data-feather="settings" /></span> Table settings
        </h3>
        <button
          type="button"
          class="btn-close text-reset"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
          @click="closeOffcanva"
        />
      </div>
      <div class="offcanvas-body">
        <p>Turn display on or off and reorder fields</p>
        <ul
          id="basic-list-group"
          class="list-group"
        >
          <draggable
            v-model="realFields"
            group="people"
            @start="drag=true"
            @end="drag=false"
          >

            <li
              v-for="field in realFields"
              :key="field.order"
              class="list-group-item draggable"
            >
              <div class="d-flex">
                <div class="more-info">
                  <div class="form-check">
                    <input
                      id="tableColumn1"
                      v-model="field.checked"
                      type="checkbox"
                      class="form-check-input"
                      :checked="field.checked"
                    >
                    <label
                      class="form-check-label"
                      for="tableColumn1"
                    >{{ field.name }}</label>
                  </div>
                </div>
                <span
                  v-b-toggle="`collapseTableColumns-${field.id}`"
                  class="mx-2"
                ><i data-feather="edit" /></span>
                <span><i data-feather="menu" /></span>
              </div>
              <b-collapse
                :id="`collapseTableColumns-${field.id}`"
              >
                <div class="mb-1 mt-1">
                  <label
                    for=""
                    class="form-label"
                  >Tooltip</label>
                  <input
                    v-model="field.tooltip"
                    type="text"
                    class="form-control"
                  >
                </div>
                <div class="mb-1">
                  <label
                    for=""
                    class="form-label"
                  >Label</label>
                  <input
                    v-model="field.label"
                    type="text"
                    class="form-control"
                  >
                </div>
                <div class="mb-1">
                  <label
                    for=""
                    class="form-label"
                  >Width</label>
                  <select
                    v-model="field.width"
                    class="form-select"
                  >
                    <option value="400">
                      XL
                    </option>
                    <option value="300">
                      L
                    </option>
                    <option value="150">
                      M
                    </option>
                    <option value="100">
                      S
                    </option>
                    <option value="50">
                      XS
                    </option>
                  </select>
                </div>
              </b-collapse>
            </li>
          </draggable>
        </ul>
      </div>
      <div class="offcanvas-footer mt-auto">
        <button
          type="button"
          class="btn btn-dark mb-1 d-grid w-100"
          @click="applyChanges"
        >
          Apply changes
        </button>
      </div>
    </div>
    <div
      v-if="visible"
      class="offcanvas-backdrop fade"
      :class="{ show: visible }"
      @click="closeOffcanva"
    />
  </div>
  <!-- -->
</template>

<script>
import { mapGetters } from 'vuex'
import Draggable from 'vuedraggable'
import { BCollapse } from 'bootstrap-vue'

export default {
  components: {
    Draggable,
    BCollapse,
  },
  data() {
    return {
      realFields: [],
      loading: false,
    }
  },
  computed: {
    ...mapGetters({
      visible: 'modals/tableSettings',
      fields: 'modals/tableSettingsFields',
      table: 'modals/tableSettingsTable',
    }),
  },
  watch: {
    fields() {
      if (this.fields) {
        this.realFields = Object.keys(this.fields).map(key => this.fields[key])
      }
    },
  },
  async mounted() {
    this.realFields = Object.keys(this.fields).map(key => this.fields[key])
    feather.replace({
      width: 14,
      height: 14,
    })
  },
  methods: {
    closeOffcanva() {
      this.$store.dispatch('modals/toggleTableSettings', false)
    },
    async applyChanges() {
      await this.$store.dispatch('modals/fetchFields', {
        fields: this.realFields,
        table: this.table,
      })
      this.closeOffcanva()
      feather.replace({
        width: 14,
        height: 14,
      })
    },
    async save() {
      this.loading = true
      await this.$store.dispatch('modals/fetchFields', {
        fields: this.realFields,
        table: this.table,
      })
      this.$toastr.success('', 'Field saved successfully!')
      this.loading = false
      feather.replace({
        width: 14,
        height: 14,
      })
    },
  },
}
</script>
